import {Button, Card, Col, Form, Input, Row, Select, Spin, Typography} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {EMAIL_REGEX, MOBILE_REGEX} from "../../../utils/common";
import {layout} from "../../../constant/dataKey";
import {getAPI} from "../../../utils/apiRequest";
import {
    CHECK_ONBOARD_TAX,
    CITY,
    COUNTRY,
    STATE,
    SUBSCRIPTION_PLANS,
    VALIDATE_ON_BOARDING_COUPON
} from "../../../constant/api";
import {_debounce} from "../../../utils/lodashUtils";
import {CheckOutlined, CloseOutlined, LoadingOutlined} from "@ant-design/icons";

const {Text} = Typography
let suffixState = ''
const BasicForm = (props) => {
    const [loading, setLoading] = useState(false)
    const [planData, setPlanList] = useState([])
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [coupon, setCoupon] = useState(null)
    const [appliedCoupon, setAppliedCoupon] = useState(null)
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [checkCoupon, setCheckCoupon] = useState(false)
    const [selectedState, setSelectedState] = useState(null)
    const [couponError, setCouponError] = useState(false)
    const [finalPrice, setFinalPrice] = useState(0)
    const [couponCode, setCouponCode] = useState('')

    const debpunceQuery = _debounce((value) => setCoupon(value), 1000);
    const tailLayout = {
        wrapperCol: {xs: {span: 24}, sm: {span: 12, offset: 12}, md: {span: 12, offset: 8}, lg: {span: 16, offset: 6}}
    };

    let formRef = useRef();

    useEffect(() => {
        if (coupon && couponCode) {
            validateCoupon(selectedPlan.id, coupon, props.selectedProfessionData)
        } else {
            suffixState = '';
            // setFinalPrice(selectedPlan?.price);
            setCouponError(null);
            // formRef.current.setFieldsValue({price: selectedPlan?.price ? selectedPlan?.price : 0});
        }

    }, [coupon, selectedPlan, props.selectedProfessionData])


    useEffect(() => {
        if (selectedState && selectedPlan?.id){
            checkOnboardTax(
                coupon,
                selectedPlan?.id,
                props.selectedProfessionData,
                selectedState
            )
        }

    }, [coupon, selectedPlan, props.selectedProfessionData, selectedState])

    useEffect(() => {
        loadPlans()
        getCountry()
    }, [])

    const onChangePlan = (value, option) => {
        suffixState = ''
        props.setPlan(value)
        setSelectedPlan(option?.data)
    }
    const getCountry = () => {
        setLoading(true)
        let apiParams = {
            pagination: false,
        }

        let successFn = function (result) {
            setLoading(false)
            setCountryList(result)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(COUNTRY, successFn, errorFn, apiParams)
    }
    const getState = (id) => {
        setLoading(true)

        let apiParams = {
            pagination: false,
            country: id
        }
        let successFn = function (result) {
            setLoading(false)
            setStateList(result)
        }

        let errorFn = function (error) {
            setLoading(false)
            console.log(error)
        }
        getAPI(STATE, successFn, errorFn, apiParams)
    }
    const getCity = (id) => {

        setLoading(true);
        let apiParams = {
            pagination: false,
            state: id
        }
        let successFn = function (result) {
            setLoading(false);
            setCityList(result)
        }
        let errorFn = function (error) {
            console.log(error)
        }
        getAPI(CITY, successFn, errorFn, apiParams)
    }

    const loadPlans = () => {
        let apiParams = {
            pagination: false
        }
        let successFn = function (result) {
            for (let i in result) {
                if (props.selectedProfessionData === "Other") {
                    result[i].price = result[i].price * 2;
                }
                if (result[i]?.id === parseInt(props.plan)) {
                    setSelectedPlan(result[i]);
                    formRef.current.setFieldsValue({
                        plan: result[i]?.id
                    });
                }
            }
            setPlanList(result)
        }
        let errorFn = function () {

        }
        getAPI(SUBSCRIPTION_PLANS, successFn, errorFn, apiParams)
    }
    const handleCountryChange = (id) => {
        formRef.current.setFieldsValue({
            state: "",
            city: ""
        })
        getState(id)
    }

    const handleStateChange = (id) => {
        formRef.current.setFieldsValue({
            city: ""
        })
        setSelectedState(id)
        getCity(id)
    }

    const handleCouponChange = (e) => {
        if (e.target.value){
            setCouponCode(e.target.value)
            debpunceQuery(e.target.value)
        }else {
            setAppliedCoupon(null)
            debpunceQuery(null)
            checkOnboardTax(coupon,
                selectedPlan?.id,
                props.selectedProfessionData,
                selectedState)
            setCouponCode(null)
            suffixState=''
        }

    }
    const validateCoupon = (plan, coupon, profession) => {
        suffixState = <LoadingOutlined/>
        setCheckCoupon(false)
        let apiParams = {
            plan,
            coupon_code: coupon,
            profession
        }
        let successFn = function (result) {
            setAppliedCoupon(result?.discount)
            suffixState = <CheckOutlined
                style={{
                    fontSize: 16,
                    color: "#52c41a",
                }}
            />
            setCheckCoupon(true)
            // setFinalPrice(result?.final_price)
            // formRef.current.setFieldsValue({price: result?.final_price ? result?.final_price : 0});
        }
        let errorFn = function (error) {
            setAppliedCoupon(null);
            // setFinalPrice(selectedPlan?.price);
            setCouponError(error?.response?.data?.detail);
            // formRef.current.setFieldsValue({price: selectedPlan?.price ? selectedPlan?.price : 0});
            suffixState = <CloseOutlined
                style={{
                    fontSize: 16,
                    color: "#ff4d4f",
                }}
            />
            setCheckCoupon(false)
        }
        getAPI(VALIDATE_ON_BOARDING_COUPON, successFn, errorFn, apiParams);
    }

    const checkOnboardTax = (coupon_code, plan, profession, state) => {
        let apiParams = {
            coupon_code,
            plan,
            profession,
            state
        }
        let successFn = function (result) {
            console.log("==========>", result)
            setFinalPrice(result?.total_amount)
            formRef.current.setFieldsValue({price: result?.total_amount || 0});
        }
        let errorFn = function (error) {

        }
        getAPI(CHECK_ONBOARD_TAX, successFn, errorFn, apiParams)
    }

    return (
        <Spin spinning={loading}>
            <Form name="form1"
                  initialValues={{
                      plan: props.plan ? props.plan : null
                  }}
                  onFinish={props.onSuccess}
                  ref={formRef}

                  {...layout}
                  layout="horizontal">
                <Form.Item name={'org_name'} label={'Organization Name'}
                           rules={[{required: true, message: 'Please Enter Your Organization Name'}]}
                >
                    <Input placeholder={'Organization Name'}/>
                </Form.Item>
                <Form.Item
                    name={'gst_no'}
                    label={'GST NO'}
                    rules={[{required: true}]}
                >
                    <Input placeholder={"GST NO"}/>
                </Form.Item>

                <Form.Item label={"Email Id"} name={'email'}
                           rules={[{required: true},
                               {
                                   pattern: EMAIL_REGEX,
                                   message: 'Please enter a valid email address'
                               }]}>
                    <Input placeholder={'Email Id'}/>
                </Form.Item>
                <Form.Item label={"Mobile No"} name={'mobile'} rules={[{required: true},
                    {
                        pattern: MOBILE_REGEX,
                        message: 'Please enter a valid mobile number'
                    }]}>
                    <Input placeholder={'Mobile No'}/>
                </Form.Item>
                <Form.Item
                    label={'Address'}
                    name={'address'}
                    rules={[{required: true}]}
                >
                    <Input placeholder={'Address'}/>
                </Form.Item>
                <Form.Item
                    label={'Country'}
                    name={'country'}
                    rules={[{required: true}]}
                >
                    <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'Country'}
                        onChange={handleCountryChange}
                        optionFilterProp="children"
                    >

                        {countryList.map((option) => (
                            <Select.Option label={option.label} key={option.id} value={option.id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'State'}
                    name={'state'}
                    rules={[{required: true}]}
                >
                    <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'State'}
                        onChange={handleStateChange}
                        optionFilterProp="children"
                    >
                        {stateList.map((option) => (
                            <Select.Option label={option.label} key={option.id} value={option.id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'City'}
                    name={'city'}
                    rules={[{required: true}]}
                >
                    <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        placeholder={'City'}
                        optionFilterProp="children"
                    >
                        {cityList.map((option) => (
                            <Select.Option label={option.label} key={option.id} value={option.id}>
                                {option.name} ({option.state_data.name})
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={"Plan"}
                    name={"plan"}
                    rules={[{required: true}]}>
                    <Select
                        showSearch
                        allowClear
                        style={{width: "100%"}}
                        placeholder={"Plan"}
                        onChange={(value, option) => onChangePlan(value, option)}
                    >
                        {planData?.map((option) => (<Select.Option
                            key={option.id}
                            value={option.id}
                            data={option}
                        >
                            {option.name} (INR {option?.price}) - {option?.expiry_days} Days
                        </Select.Option>))}
                    </Select>
                </Form.Item>
                {selectedPlan && selectedPlan?.features_data?.length > 0 ?
                    <><Card style={{width: props.hideNav ? "92%" : "70%", marginLeft: props.hideNav ? 8 : "21%"}}
                            bodyStyle={{padding: 8, textAlign: "left"}}><React.Fragment>
                        {selectedPlan?.features_data?.map((item) =>
                            <DescriptionItem
                                content={item}
                            />)}
                    </React.Fragment></Card><br/></> : null}
                {selectedPlan ?
                    <Form.Item name={"coupon"} label={"Coupon"}
                               extra={appliedCoupon ? (
                                   <Text style={{color: "#31A6C7"}}>
                                       Congratulations! ₹ {appliedCoupon} discount applied.
                                   </Text>
                               ) : (couponError && couponCode ? <Text style={{color: "red"}}>
                                   {couponError}
                               </Text> : null)}>
                        <Input
                            suffix={suffixState}
                            onChange={handleCouponChange}
                            placeholder={"Coupon"}
                        />
                    </Form.Item> : null}
                {selectedPlan ?
                    <Form.Item name={"price"} label={"Payable Amount"}
                               initialValue={finalPrice}>
                        <Input
                            // suffix={suffixState}
                            placeholder={"Price"}
                            disabled={true}
                        />
                    </Form.Item> : null}


                <Form.Item  {...tailLayout}>
                    <Button htmlType="button" onClick={props.onBack} style={{margin: '0 8px'}}>
                        Back
                    </Button>
                    <Button htmlType="submit" type="primary">
                        Pay Now
                    </Button>

                </Form.Item>
            </Form>
        </Spin>
    )
}

export default BasicForm

const DescriptionItem = ({title, content}) => (
    <Row gutter={8} style={{marginBottom: '5px'}}>
        <Col span={22} offset={1}>
            <ul>
                <li style={{}}> {title}{title ? ": " : ""}{content}</li>
            </ul>
        </Col>
    </Row>
);
