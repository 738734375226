import {Button, Card, Result, Spin} from 'antd';
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import { PAYMENT_STATUS} from '../../constant/api';
import {getAPI} from '../../utils/apiRequest';
import {handleErrorResponse} from "../../utils/common";

function PaymentHandle(props) {

    const [paymentStatus, setPaymentStatus] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(props.match?.params?.order_id !== '999999'){
            getStatus();
        }
    }, []);

    let getStatus = () => {
        setLoading(true);
        let apiParams = {
            order_id: props.match?.params?.order_id,
        }
        let successFn = function (data) {
            setPaymentStatus(true);
            setLoading(false);

        }
        let errorFn = function (error) {
            setPaymentStatus(false);
            setLoading(false);
            handleErrorResponse(error)
        }
        getAPI(PAYMENT_STATUS, successFn, errorFn, apiParams)

    }

    return (
        <Spin spinning={loading}>
            <Card>
                {paymentStatus && !loading || props.match?.params?.order_id === '999999'  ?
                    <Result
                        status="success"
                        title="Payment Successfully"
                        subTitle="Your Payment has Been Received. you will be contacted by our team for further process."
                        extra={
                            <a href={'https://caclouddesk.com/'}><Button
                                className='primary-btn' type='primary'>
                                {'Go To Home'}
                            </Button>
                            </a>
                        }
                    /> : !loading ? <Result
                        status="error"
                        title="Payment Failed"
                        subTitle="Your Payment has been failed "
                        extra={
                            <a href={'https://caclouddesk.com/'}><Button
                                className='primary-btn' type='primary'>
                                {'Go To Orders'}
                            </Button>
                            </a>
                        }/> : null}
            </Card>
        </Spin>
    )
}

export default PaymentHandle;
