import React, {useEffect, useState} from "react";
import {Row, Col, Spin} from "antd";
import KycForm from "./KycForm";
import BasicForm from "./BasicForm";
import {postAPI} from "../../../utils/apiRequest";
import {ON_BOARDING_PAYMENT_REQUEST, REQUEST_ON_BOARDING} from "../../../constant/api";
import {useHistory} from 'react-router-dom'
import moment from "moment";
import {cashfreeSandbox, cashfreeProd} from "cashfree-pg-sdk-javascript";
import {displayMessage} from "../../../utils/common";
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from "../../../constant/dataKey";

const OnboardingForm = (props) => {
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});
    const [uploadFileState, setUploadFileState] = useState({});
    const [selectedProfessionData, setSelectedProfessionData] = useState(null);
    const [plan, setPlan] = useState({});
    const history = useHistory()

  

    useEffect(() => {
        const params = new URLSearchParams(props.location.search);
        setPlan(params.get('type'))
        setSelectedProfessionData(params.get('profession'))

    }, [props.location])

    const handleNextStep = ((value = {}) => {
        setFormData(value);
        setStep(step + 1);
    })


    const handlePrevStep = (() => {
        setFormData(formData);
        setStep(step - 1);
    })

    const onFinish = ((value) => {
        setLoading(true)
        let reqData = {
            ...formData,
            ...value,
            dob: moment(value.dob).format("YYYY-MM-DD"),
            enrollment_date: moment(value.enrollment_date).format("YYYY-MM-DD"),
            profession: selectedProfessionData ? selectedProfessionData : '',
            ...uploadFileState
        }
        let successFn = function (result) {
            orderPayment({result, coupon: value.coupon})
            setLoading(false)
        }
        let errorFn = function (error) {
            setLoading(false)
            Object.values(error).map((item) => displayMessage(ERROR_MSG_TYPE, item));
        }
        postAPI(REQUEST_ON_BOARDING, reqData, successFn, errorFn)
    })


    const orderPayment = (value) => {
        let reqData = {
            id: value.result?.id,
            coupon_code:value.coupon
        }
        let successFn = function (result) {
            cashFreeOpen(result)
        }
        let errorFn = function (error) {

        }
        postAPI(ON_BOARDING_PAYMENT_REQUEST, reqData, successFn, errorFn,)

    }

    const cashFreeOpen = ({session_id, order_id}) => {
        let cashfree;
        if (JSON.parse(process.env.REACT_APP_CASH_FREE_PROD)) {
            cashfree = new cashfreeProd.Cashfree(session_id);
        } else {
            cashfree = new cashfreeSandbox.Cashfree(session_id);
        }

        if(session_id !== null){
            cashfree.redirect();
        }
        else{
            history.push(`/payment_status/${999999}`)
        }
    }
    const onDeleteUploadFile = (fileName) => {
        setUploadFileState(prevState => {
            const { [fileName]: deletedKey, ...rest } = prevState;
            return rest;
          });
        displayMessage(SUCCESS_MSG_TYPE,`${uploadFileState[fileName]} deleted SuccessFully`)
    }

    return (
        <Row justify={'start'} align={'middle'}>
            <Col xs={22} sm={22} md={22} lg={22} xl={22} offset={2}>
                <h2 className={'auth-type-title'}>Step {step} of 2</h2>
                <p className={'auth-type-title'} style={{marginBottom: "2em"}}>
                    {step === 2 ? 'Organization' : 'Profession'} Details</p>
                <br/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign: "start"}}>
                <Spin spinning={loading}>
                    {step === 2 &&
                        <BasicForm
                            plan={plan}
                            setPlan={setPlan}
                            onBack={handlePrevStep}
                            onSuccess={onFinish}
                            selectedProfessionData={selectedProfessionData}
                            hideNav={props.hideNav}
                        />
                    }
                    {step === 1 &&
                        <KycForm
                            formData={formData}
                            onNext={handleNextStep}
                            setUploadFileState={setUploadFileState}
                            uploadFileState={uploadFileState}
                            setSelectedProfessionData={setSelectedProfessionData}
                            selectedProfessionData={selectedProfessionData}
                            onDeleteUploadFile={onDeleteUploadFile}
                        />
                    }
                </Spin>
            </Col>
        </Row>
    )
}
export default OnboardingForm