import {Button, Form, Input, DatePicker, Select, Checkbox, Radio, Typography} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {
    PROFESSION_ADVOCATE_ID,
    PROFESSION_CA_ID,
    PROFESSION_CMA_ID,
    PROFESSION_CS_ID,
    PROFESSION_ACCOUNTANT_ID,
    PROFESSION_LIST, layout
} from "../../../constant/dataKey";
import UploadFile from "../../common/uploadFile";
const {Text} = Typography


const KycForm = (props) => {
    const [selectedProfession, setSelectedProfession] = useState(null);
    let formRef = useRef();

    const tailLayout = {
        wrapperCol: {xs: {span: 24}, sm: {span: 12, offset: 12}, md: {span: 12, offset: 8}, lg: {span: 16, offset: 6}}
    };


    useEffect(() => {
        if (props.selectedProfessionData === 'Other') {
            setSelectedProfession(props.selectedProfessionData)
            formRef.current.resetFields();
        }
    }, [props.selectedProfessionData])

    useEffect(() => {
        if (props.formData?.profession) {
            setSelectedProfession(props.formData?.profession)
        }
    }, [props.formData])

    const handleProfessionChange = (value, option) => {
        setSelectedProfession(() => value);
        props?.setSelectedProfessionData(() => option?.key);
        props?.setUploadFileState({});
    };





    const changeProfessionType = (type) => {
        switch (type) {
            case PROFESSION_CA_ID:
                return (<>
                    <Form.Item label="MRN Number" name={"mrn_number"}
                               rules={[{required: true}]}>
                        <Input name="mrn_number" placeholder="MRN Number"/>
                    </Form.Item>
                    <Form.Item label="Enrollment Date" name={"enrollment_date"}
                               rules={[{required: true}]}>
                        <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                    </Form.Item>

                    <Form.Item label="Date of Birth" name={"dob"}
                               rules={[{required: true}]}>
                        <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                    </Form.Item>
                    {/* <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                        <Input placeholder="Firm Name"/>
                    </Form.Item> */}
                    <Form.Item
                        label="Upload ACA/FCA Certificate"
                        valuePropName='defaultFileList'
                    >
                        <UploadFile
                            key={`Upload ACA/FCA certificate1 ${Object.keys(props.uploadFileState).length}`}
                            name={"aca_fca_upload"}
                            label={' '}
                            colon={false}
                            initialValue={props.uploadFileState['aca_fca_upload'] ? props.uploadFileState['aca_fca_upload'] : undefined}
                            setState={props.setUploadFileState}
                            uploadFileState={props.uploadFileState}
                            onDeleteUploadFile={props.onDeleteUploadFile}
                        />
                    </Form.Item>
                    <Form.Item   label={`Tally Integration Required (FREE)`} name={'is_tally'} rules={[{required: true}]}>
                        <Radio.Group>
                            <Radio value={'yes'}>Yes</Radio>
                            <Radio value={'No'}>No</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="Upload Photograph" name={"photo"}
                               valuePropName='defaultFileList'>
                        <UploadFile
                            initialValue={props.uploadFileState['photo'] ? props.uploadFileState['photo'] : undefined}
                            key={'Upload your Photograph2'}
                            name={"photo"}
                            label={' '}
                            colon={false}
                            setState={props.setUploadFileState}
                            uploadFileState={props.uploadFileState}
                            onDeleteUploadFile={props.onDeleteUploadFile}
                        />
                    </Form.Item>

                </>);
            case PROFESSION_ADVOCATE_ID:
                return (<>
                    <Form.Item label="Name" name={"name"}
                               rules={[{required: true}]}>
                        <Input width={100} placeholder="Name"/>
                    </Form.Item>
                    <Form.Item label="Date of Birth" name={"dob"}
                               rules={[{required: true}]}>
                        <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                    </Form.Item>
                    <Form.Item
                        label="Enter Enrollment no."
                        name={"enrollment_number"}
                        rules={[{required: true}]}
                    >
                        <Input placeholder="Enrollment No."/>
                    </Form.Item>
                    <Form.Item
                        label="Enter registration no"
                        name={"registration_number"}
                    >
                        <Input placeholder="Registration No."/>
                    </Form.Item>

                    {/* <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                        <Input placeholder="Firm Name"/>
                    </Form.Item> */}
                    <Form.Item label="Upload LLB Degree"
                               valuePropName='defaultFileList'>
                        <UploadFile
                            initialValue={props.uploadFileState['llb_degree_upload'] ? props.uploadFileState['llb_degree_upload'] : undefined}
                            key={'Upload LLB Degree3'}
                            name={"llb_degree_upload"}
                            label={' '}
                            colon={false}
                            setState={props.setUploadFileState}
                            uploadFileState={props.uploadFileState}
                            onDeleteUploadFile={props.onDeleteUploadFile}
                        />

                    </Form.Item>
                    <Form.Item   label={`Tally Integration Required (FREE)`} name={'is_tally'} rules={[{required: true}]}>
                        <Radio.Group>
                            <Radio value={'yes'}>Yes</Radio>
                            <Radio value={'No'}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        valuePropName='defaultFileList'
                        label="Upload Photograph">
                        <UploadFile
                            initialValue={props.uploadFileState['photo'] ? props.uploadFileState['photo'] : undefined}
                            key={'Upload LLB Degree4'}
                            name={"photo"}
                            label={' '}
                            colon={false}
                            setState={props.setUploadFileState}
                            uploadFileState={props.uploadFileState}
                            onDeleteUploadFile={props.onDeleteUploadFile}
                        />

                    </Form.Item>
                </>);
            case PROFESSION_CS_ID:
                return (<>
                    <Form.Item label="MRN Number" name={"mrn_number"}
                               rules={[{required: true}]}>
                        <Input name="mrn_number" placeholder="MRN Number"/>
                    </Form.Item>
                    <Form.Item label="Enrollment Date" name={"enrollment_date"}
                               rules={[{required: true}]}>
                        <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                    </Form.Item>

                    <Form.Item label="Date of Birth" name={"dob"}
                               rules={[{required: true}]}>
                        <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                    </Form.Item>
                    {/* <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                        <Input width={"100%"} placeholder="Firm Name"/>
                    </Form.Item> */}
                    <Form.Item
                        label="Upload Practicing Certificate"
                        valuePropName='defaultFileList'
                    >
                        <UploadFile
                            initialValue={props.uploadFileState['practicing_upload'] ? props.uploadFileState['practicing_upload'] : undefined}
                            key={'Upload your Practicing Certificate5'}
                            name={"practicing_upload"}
                            label={' '}
                            colon={false}
                            setState={props.setUploadFileState}
                            uploadFileState={props.uploadFileState}
                            onDeleteUploadFile={props.onDeleteUploadFile}
                        />
                    </Form.Item>
                    <Form.Item   label={`Tally Integration Required (FREE)`} name={'is_tally'} rules={[{required: true}]}>
                        <Radio.Group>
                            <Radio value={'yes'}>Yes</Radio>
                            <Radio value={'No'}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Upload Photograph"
                               valuePropName='defaultFileList'>
                        <UploadFile
                            initialValue={props.uploadFileState['photo'] ? props.uploadFileState['photo'] : undefined}
                            key={'Upload your Photograph6'}
                            name={"photo"}
                            label={' '}
                            colon={false}
                            setState={props.setUploadFileState}
                            uploadFileState={props.uploadFileState}
                            onDeleteUploadFile={props.onDeleteUploadFile}
                        />
                    </Form.Item>
                </>);
            case PROFESSION_CMA_ID:
                return (<>
                    <Form.Item label="MRN Number" name={"mrn_number"}
                               rules={[{required: true}]}>
                        <Input name="mrn_number" placeholder="MRN Number"/>
                    </Form.Item>
                    <Form.Item label="Enrollment Date" name={"enrollment_date"}
                               rules={[{required: true}]}>
                        <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                    </Form.Item>

                    <Form.Item label="Date of Birth" name={"dob"}
                               rules={[{required: true}]}>
                        <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                    </Form.Item>
                    {/* <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                        <Input placeholder="Firm Name"/>
                    </Form.Item> */}
                    <Form.Item
                        label="Upload Participating Certificate"
                        name={"practicing_upload"}
                        valuePropName='defaultFileList'
                    >
                        <UploadFile
                            initialValue={props.uploadFileState['practicing_upload'] ? props.uploadFileState['practicing_upload'] : undefined}
                            key={'Upload your Participating Certificate7'}
                            name={"practicing_upload"}
                            label={' '}
                            colon={false}
                            setState={props.setUploadFileState}
                            uploadFileState={props.uploadFileState}
                            onDeleteUploadFile={props.onDeleteUploadFile}
                        />
                    </Form.Item>
                    <Form.Item   label={`Tally Integration Required (FREE)`} name={'is_tally'} rules={[{required: true}]}>
                        <Radio.Group>
                            <Radio value={'yes'}>Yes</Radio>
                            <Radio value={'No'}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Upload Photograph"
                               valuePropName='defaultFileList'>
                        <UploadFile
                            initialValue={props.uploadFileState['photo'] ? props.uploadFileState['photo'] : undefined}
                            key={'Upload your Photograph8'}
                            name={"photo"}
                            label={' '}
                            colon={false}
                            setState={props.setUploadFileState}
                            uploadFileState={props.uploadFileState}
                            onDeleteUploadFile={props.onDeleteUploadFile}
                        />
                    </Form.Item>
                </>);
            case PROFESSION_ACCOUNTANT_ID:
                return (<>
                    <Form.Item label="Name" name={"name"} rules={[{required: true}]}>
                        <Input placeholder="Name"/>
                    </Form.Item>

                    <Form.Item label="Date of Birth" name={"dob"}
                               rules={[{required: true}]}>
                        <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                    </Form.Item>
                    {/* <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                        <Input placeholder="Firm Name"/>
                    </Form.Item> */}
                    <Form.Item   label={`Tally Integration Required (FREE)`} name={'is_tally'} rules={[{required: true}]}>
                        <Radio.Group>
                            <Radio value={'yes'}>Yes</Radio>
                            <Radio value={'No'}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        valuePropName='defaultFileList' label="Upload Photograph">
                        <UploadFile
                            initialValue={props.uploadFileState['photo'] ? props.uploadFileState['photo'] : undefined}
                            key={'Upload your Photograph9'}
                            name={"photo"}
                            label={' '}
                            colon={false}
                            setState={props.setUploadFileState}
                            uploadFileState={props.uploadFileState}
                            onDeleteUploadFile={props.onDeleteUploadFile}
                        />
                    </Form.Item>
                </>);
            default:
                return (<>
                    <Form.Item label="Name" name={"name"} rules={[{required: true}]}>
                        <Input placeholder="Name"/>
                    </Form.Item>
                    <Form.Item label="Date of Birth" name={"dob"}
                               rules={[{required: true}]}>
                        <DatePicker format={"DD/MM/YYYY"} style={{width: "100%"}} placeholder="DD/MM/YYYY"/>
                    </Form.Item>
                    <Form.Item label="Enter Profession" name={"professional"}
                               rules={[{required: true}]}>
                        <Input placeholder="Profession (Eg. Banker, DSA, Architect, Shopkeeper, VLE, etc) "/>
                    </Form.Item>
                    {/* <Form.Item label="Firm Name (if any) " name={"firm_name"}>
                        <Input placeholder="Firm Name"/>
                    </Form.Item> */}
                    <Form.Item   label={`Tally Integration Required (FREE)`} name={'is_tally'} rules={[{required: true}]}>
                        <Radio.Group>
                            <Radio value={'yes'}>Yes</Radio>
                            <Radio value={'No'}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Upload Photograph"
                               valuePropName='defaultFileList'>
                        <UploadFile
                            initialValue={props.uploadFileState['photo'] ? props.uploadFileState['photo'] : undefined}
                            key={'Upload your Photograph10'}
                            name={"photo"}
                            label={' '}
                            colon={false}
                            setState={props.setUploadFileState}
                            uploadFileState={props.uploadFileState}
                            onDeleteUploadFile={props.onDeleteUploadFile}
                        />
                    </Form.Item>
                </>);
        }
    };
    return (
        <Form name="form2"
              {...layout}
              initialValues={{
                  ...props.formData,
                  profession: props.selectedProfessionData ? props.selectedProfessionData : null
              }}
              onFinish={props.onNext}
              ref={formRef}
              layout="horizontal"
              labelAlign="left"
              labelWrap
              colon={false}

        >
            <Form.Item
                label={"Profession"}
                name={"profession"}
                rules={[{required: true}]}
            >
                <Select
                    showSearch
                    allowClear
                    style={{width: "100%"}}
                    placeholder={"Profession"}
                    onChange={(value, option) => handleProfessionChange(value, option)}
                >
                    {PROFESSION_LIST?.map((option) => (<Select.Option
                        key={option.key}
                        value={option.value}
                    >
                        {option.name}
                    </Select.Option>))}
                </Select>
            </Form.Item>

            {changeProfessionType(selectedProfession)}

            <Form.Item  {...tailLayout}>
                <Button htmlType="submit" type="primary">
                    Next
                </Button>

            </Form.Item>
        </Form>
    )
}

export default KycForm