/*** Base URLs **/
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const API_URL = BACKEND_BASE_URL + '/seva-api/v1';
export const IMAGE_BASE_URL = BACKEND_BASE_URL + '/onboard-media';
export const GET_FILE_UPLOAD_SIGNATURE = "uploads/onboard_presigned_url/";
export const VIEW_UPLOADED_FILE = 'uploads/download_file/';
export const REQUEST_ON_BOARDING = 'admin_settings/on_boarding/';
export const PAYMENT_STATUS = "admin_settings/onbording_status/";
export const ON_BOARDING_PAYMENT_REQUEST = "admin_settings/request_onbording/";
export const SUBSCRIPTION_PLANS = "admin_settings/subscription_plan/";
export const VALIDATE_ON_BOARDING_COUPON = "admin_settings/check_onboard_coupon/";

export const COUNTRY = "admin_settings/country/"
export const STATE = "admin_settings/state/"
export const CITY = "admin_settings/city/"
export const CHECK_ONBOARD_TAX = "admin_settings/check_onboard_tax/"