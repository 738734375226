export const SUCCESS_MSG_TYPE = 'success';
export const WARNING_MSG_TYPE = 'warning';
export const ERROR_MSG_TYPE = 'error';
export const INFO_MSG_TYPE = 'info';
export const PROFESSION_CA_ID = 1;
export const PROFESSION_ADVOCATE_ID = 2;
export const PROFESSION_CS_ID = 3;
export const PROFESSION_CMA_ID = 4;
export const PROFESSION_ACCOUNTANT_ID = 5;

export const PROFESSION_LIST=[
    {name:'CA',value:1, key:"CA"},
    {name:'Advocate',value:2, key:"Advocate"},
    {name:'CS',value:3, key:"CS"},
    {name:'CMA',value:4, key:"CMA"},
    {name:'Accountant',value:5, key:"Accountant"},
    {name:'Other',value:'Other', key:"Other"},
]




export const layout = {
    labelCol: {xs: {span: 24}, sm: {span: 12}, md: {span: 6}, lg: {span: 6}},
    wrapperCol: {xs: {span: 24}, sm: {span: 12}, md: {span: 12}, lg: {span: 17}}
};
export const tailLayout = {
    wrapperCol: {xs: {span: 24}, sm: {span: 12, offset: 12}, md: {span: 12, offset: 8}, lg: {span: 16, offset: 5}}
};


export const SUBSCRIPTION_PLAN=[
    {name:'Silver',value:'Silver', key:"Silver", amount:3000},
    {name:'Gold',value:'Gold', key:"Gold", amount:10000},
    {name:'Gold +',value:'GoldPlus', key:"GoldPlus", amount:20000},
]