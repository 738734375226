import {Button, Space} from "antd";
import React from "react";
import {getAPI} from "../../../utils/apiRequest";
import {VIEW_UPLOADED_FILE} from "../../../constant/api";
import {handleErrorResponse, makeFileURL, startLoadingMessage, stopLoadingMessage} from "../../../utils/common";
import {ERROR_MSG_TYPE, SUCCESS_MSG_TYPE} from "../../../constant/dataKey";
import {DeleteOutlined} from '@ant-design/icons'

const ViewUploadedFile = (props) => {

    const viewUploadedFile = async () => {
        let apiParams = {
            path: props.value
        }
        let msg = startLoadingMessage('Generating File ...');
        let successFn = function (data) {
            if (data.url) {
                window.open(makeFileURL(data.url));
            }
            stopLoadingMessage(
                msg,
                SUCCESS_MSG_TYPE,
                'File Generated Successfully!',
            );
        }
        let errorFn = function (error) {
            handleErrorResponse(error);
            stopLoadingMessage(msg, ERROR_MSG_TYPE, 'File Generation Failed!');
        }
        await getAPI(VIEW_UPLOADED_FILE, successFn, errorFn, apiParams)
    }
   const onDelete=()=>{
       props.onDeleteUploadFile(props.name)
   }

    return (
        <Space>
            <Button type={'link'} onClick={viewUploadedFile}>View Uploaded</Button>
            <DeleteOutlined onClick={onDelete} type={'danger'}/>
        </Space>
    )
}

export default ViewUploadedFile