import Onboarding from "./component/onboard";
import {Redirect, Route, Switch, BrowserRouter as Router} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Paysuccess from "./component/paysuccess";

function App() {
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={['/', '/onboarding']}
                    render={(route) => <Onboarding {...route} />
                    }
                />
                <Route
                    exact
                    path={[`/payment_status/:order_id`]}
                    render={(route) => <Paysuccess  {...route}/>
                    }
                />
            </Switch>
        </Router>
    );
}

export default App;
