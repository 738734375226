import React from 'react'
import {Row, Col, Typography} from "antd";
import OnboardingForm from "./From";

const {Title, Text} = Typography

class Onboarding extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            hideNav: true
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({hideNav: window.innerWidth <= 760});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    render() {
        return (<Row>
            {this.state.hideNav ? null : <Col md={12} lg={12} xl={12} sm={24} xs={0} className={"authMainLayout"}>
                <Row style={{paddingTop: "15vh", paddingLeft: "5vh"}}>
                    <img alt={"CA Clouddesk Logo"} style={{width: "11em"}} src={"/ca_clouddesk_logo.png"}/>
                </Row>
                <Row style={{marginTop: 40}}>
                    <Col span={1} style={{backgroundColor: "white", height: "18em", maxWidth: "0.36%"}}/>
                    <Col span={18} offset={1}><Title
                        style={{fontSize: "4em", color: "white", fontWeight: "bold", textAlign: "start"}}>Welcome
                        to CA Cloud Desk</Title><Text style={{color: "white", fontSize: 14}}>Let’s get you all set up so
                        you can create a your personal account and start your exciting journey</Text></Col>
                </Row>
            </Col>
            }
            <Col md={12} lg={12} xl={12} sm={24} xs={24} style={{width: "100%"}}>
                <div style={{
                    padding: this.state.hideNav ? 15 : 20,
                    marginTop: this.state.hideNav ? "2em" : 10,
                    // textAlign:'center'
                }}>
                    {this.state.hideNav ?
                        <img alt={"CA Clouddesk Logo"} style={{width: "11em"}}
                             src={"/ca_clouddesk_logo.png"}/> : null}
                    <OnboardingForm {...this.props} hideNav={this.state.hideNav}/>

                </div>
            </Col>
        </Row>)
    }
}

export default Onboarding